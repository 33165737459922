import "./styles.css";
import YouTube from "react-youtube";

import bullet1 from "../../assets/bullet1.png";
import bullet2 from "../../assets/bullet2.png";
import bullet3 from "../../assets/bullet3.png";
import bullet4 from "../../assets/bullet4.png";
import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import iconwhats2 from "../../assets/iconwhats2.png";
import about4 from "../../assets/about4.png";
import about3_1 from "../../assets/about3_1.png";
import about3_2 from "../../assets/about3_2.png";
import about3_3 from "../../assets/about3_3.png";
import about3_4 from "../../assets/about3_4.png";
import about3_5 from "../../assets/about3_5.png";

import CtaWpp from "../../components/CTAWpp";

const About = () => {
  const opts = {
    height: "390",
    width: "640",
  };

  const optsMobile = {
    height: "300",
    width: "350",
  };

  return (
    <section id="about">
      <article className="about1">
        <div className="container">
          <div className="left">
            <h2 className="title">Demonstração</h2>

            <p className="text mobile">
              Vamos demonstrar como o{" "}
              <b>
                Xodó Pay pode proporcionar uma economia significativa para o seu
                posto de combustível
              </b>
              , levando em consideração a taxa mais baixa aplicada pelo mercado
              de 0,7% em um posto que transaciona 200 mil litros / mês.
            </p>

            <ul className="gallery">
              <li>
                <figure>
                  <img src={bullet1} alt="Bullet point" />
                </figure>

                <p className="text">Valor total transacionado: R$ 870.000,00</p>
              </li>

              <li>
                <figure>
                  <img src={bullet1} alt="Bullet point" />
                </figure>

                <p className="text">Percentual transacionado em Pix: 50%</p>
              </li>

              <li>
                <figure>
                  <img src={bullet1} alt="Bullet point" />
                </figure>

                <p className="text">
                  Valor transacionado em Pix: R$ 435.000,00
                </p>
              </li>

              <li>
                <figure>
                  <img src={bullet2} alt="Bullet point" />
                </figure>

                <p className="text">
                  <b>Taxa de pagamento por adquirência (mercado):</b> 0,7%
                </p>
              </li>

              <li>
                <figure>
                  <img src={bullet3} alt="Bullet point" />
                </figure>

                <p className="text">
                  <b>Assinatura Xodó Pay:</b> R$ 299,00.
                </p>
              </li>
            </ul>

            <CtaWpp />
          </div>

          <div className="right">
            <figure>
              <img src={about1_1} alt="Taxa da Cielo..." />
            </figure>

            <figure>
              <img src={about1_2} alt="Assinatura Xodó Pay" />
            </figure>

            <p className="text">
              O quanto <b>você vai economizar</b> com a Xodó Pay:
            </p>

            <div className="gallery">
              <p className="item">R$ 2.700/mês</p>

              <p className="item">R$ 33.000,00/ano</p>
            </div>

            <CtaWpp />
          </div>
        </div>
      </article>

      <article className="about2">
        <div className="container">
          <div className="left">
            <h2 className="title">veja como funciona!</h2>

            <YouTube
              videoId="yizwB772ZqM"
              className="mobile"
              opts={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? optsMobile
                  : opts
              }
            />

            <p className="text">
              Veja na prática como é simples e fácil usar o App Xodó Pay. Dê um
              basta nas taxas excessivas sobre o faturamento do seu posto de
              combustível!
            </p>

            <CtaWpp value={"Quero assinar agora!"} img={iconwhats2} />
          </div>

          <YouTube
            videoId="yizwB772ZqM"
            className="desktop"
            opts={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? optsMobile
                : opts
            }
          />
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <h2 className="title">
            Agregando valor <span>pós Integração</span> Xodó Pay.
          </h2>

          <div className="gallery">
            <div className="item">
              <div className="top">
                <figure>
                  <img src={about3_1} alt="Ampliação da Rede de Clientes:" />
                </figure>

                <p className="title_item">Ampliação da Rede de Clientes:</p>
              </div>

              <div className="bottom">
                <figure>
                  <img src={bullet4} alt="Bullet Point" />
                </figure>

                <p className="text">
                  Acesso a frotas que utilizam nossa plataforma.
                </p>
              </div>
            </div>

            <div className="item">
              <div className="top">
                <figure>
                  <img src={about3_2} alt="Gestão Simplificada:" />
                </figure>

                <p className="title_item">Gestão Simplificada:</p>
              </div>

              <div className="bottom">
                <figure>
                  <img src={bullet4} alt="Bullet Point" />
                </figure>

                <p className="text">Relatórios detalhados.</p>
              </div>
            </div>

            <div className="item">
              <div className="top">
                <figure>
                  <img src={about3_3} alt="Suporte Especializado:" />
                </figure>

                <p className="title_item">Suporte Especializado:</p>
              </div>

              <div className="bottom">
                <figure>
                  <img src={bullet4} alt="Bullet Point" />
                </figure>

                <p className="text">
                  Atendimento dedicado para clientes corporativos.
                </p>
              </div>
            </div>

            <div className="item">
              <div className="top">
                <figure>
                  <img src={about3_4} alt="Parcerias Estratégicas:" />
                </figure>

                <p className="title_item">Parcerias Estratégicas:</p>
              </div>

              <div className="bottom">
                <figure>
                  <img src={bullet4} alt="Bullet Point" />
                </figure>

                <p className="text">
                  Clientes como a Localiza e mais de 30 Associações de proteção
                  veicular.
                </p>
              </div>
            </div>

            <div className="item">
              <div className="top">
                <figure>
                  <img src={about3_5} alt="Economia de Tempo e Recursos:" />
                </figure>

                <p className="title_item">Economia de Tempo e Recursos:</p>
              </div>

              <div className="bottom">
                <figure>
                  <img src={bullet4} alt="Bullet Point" />
                </figure>

                <p className="text">
                  Elimine processos burocráticos e pagamento em dinheiro.
                </p>
              </div>
            </div>
          </div>

          <CtaWpp />
        </div>
      </article>

      <article className="about4">
        <div className="container">
          <h2 className="title">empresas clientes e parceiras</h2>

          <figure>
            <img src={about4} alt="Empresas Parceiras" />
          </figure>
        </div>
      </article>
    </section>
  );
};

export default About;
