import React from "react";
import "./styles.css";

import logo from "../../assets/logo_topo.png";
import iconwhats_menu from "../../assets/iconwhats_menu.png";

const Menu = () => {
  return (
    <nav id="menu">
      <section className="container">
        <figure className="left">
          <img src={logo} alt="Logo" />
        </figure>

        <article className="right">
          <figure>
            <img src={iconwhats_menu} alt="Ícone do Whatsapp" />
          </figure>

          <p className="text">
            <a
              href="http://wa.me/+5508005917886?text=Olá,%20Gostaria%20de%20saber%20mais%20sobre%20a%20solução%20de%20Pix%20para%20Postos"
              target="_blank"
              rel="noreferrer"
            >
              Fale conosco: 0800 591 7886
            </a>
          </p>
        </article>
      </section>
    </nav>
  );
};

export default Menu;
