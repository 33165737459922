import { useEffect, useState } from "react";
import "./styles.css";

import icon from "../../assets/iconwhats.png";

const CtaWpp = ({ value, img }) => {
  const [text, setText] = useState("Saiba Mais!!");
  const [image, setImage] = useState(icon);

  useEffect(() => {
    if (value !== undefined) setText(value);
    if (img !== undefined) setImage(img);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button id="ctaWpp">
      <a
        href="http://wa.me/+5508005917886?text=Olá,%20Gostaria%20de%20saber%20mais%20sobre%20a%20solução%20de%20Pix%20para%20Postos"
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={image} alt="Ícone do Whatsapp" />
        </figure>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaWpp;
