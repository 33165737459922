import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import header2_1 from "../../assets/header2_1.png";
import header2_2 from "../../assets/header2_2.png";
import header2_3 from "../../assets/header2_3.png";
import header3 from "../../assets/header3.png";
import header3_1 from "../../assets/header3_1.png";
import header3_1Mobile from "../../assets/mobile/header3_1.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <div className="content">
            <h2 className="title">
              Economize muito com nossa Solução Pix{" "}
              <span>criada para postos!</span>
            </h2>

            <p className="text">
              Com a Xodó Pay, você recebe pagamentos via Pix no QR Code com taxa
              única de <span>apenas R$ 299,00/mês.</span>
            </p>

            <CtaWpp />
          </div>
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title">
            <span>receba</span> pagamentos sem intermediários.{" "}
            <span>da conta do cliente</span> direto para a sua conta!
          </h2>

          <div className="gallery">
            <div className="item">
              <figure className="left">
                <img src={header2_1} alt="Agilidade e segurança" />
              </figure>

              <div className="right">
                <p className="item_title">Agilidade e segurança</p>
                <p className="text">
                  Receba pagamentos instantâneos via Pix no QR Code.
                </p>
              </div>
            </div>

            <div className="item">
              <figure className="left">
                <img src={header2_2} alt="Fácil de usar" />
              </figure>

              <div className="right">
                <p className="item_title">Fácil de usar</p>
                <p className="text">
                  Utilize o App Xodó Pay no seu smartphone ou em nossa máquina
                  POS.
                </p>
              </div>
            </div>

            <div className="item">
              <figure className="left">
                <img src={header2_3} alt="Sem taxa sobre lucro" />
              </figure>

              <div className="right">
                <p className="item_title">Sem taxa sobre lucro</p>
                <p className="text">
                  Cobramos uma mensalidade fixa, assim você economiza muitooo!
                </p>
              </div>
            </div>
          </div>

          <CtaWpp />
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <figure className="top">
            <img src={header3} alt="Xodó Pay" />
          </figure>

          <div className="gallery">
            <div className="left">
              <h2 className="title">
                Transformando o mercado <span>com nossa abordagem Pix!</span>
              </h2>

              <p className="text">
                Enquanto as <b>concorrentes</b>, Cielo, Rede, Getnet, Stone e
                outros, oferecem a solução Pix com{" "}
                <b>taxas variáveis de 0,7% a 2,5%</b> por transação, decidimos
                inovar e oferecer uma experiência totalmente transparente e
                previsível.
              </p>

              <p className="text">
                Em vez de taxas flutuantes,{" "}
                <b>implementamos uma estrutura de preços justos e vantajosos</b>{" "}
                , cobrando apenas uma{" "}
                <span>assinatura mensal no valor de R$299,00.</span>
              </p>

              <figure className="mobile">
                <img src={header3_1Mobile} alt="Xodó Pay" />
              </figure>

              <CtaWpp />
            </div>

            <figure className="right">
              <img src={header3_1} alt="Xodó Pay" />
            </figure>
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
